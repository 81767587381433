import { defineStore, storeToRefs } from 'pinia';
import { format, setHours, setMinutes, isValid } from 'date-fns';

import { TTaskPriorityItem, filesTypes, filesTypesMapping } from '@/consts';
import { computed, reactive, ref, watch } from 'vue';
import { useUserStore } from './user';
import services from '@/api/services';
import { useTasksStore } from './tasks/tasks';

import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import {
  subordinatesMappingToView,
  SubordinateViewType,
} from '@/utils/subordinates';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { SetTaskTimeParams, Task } from '@/types/tasks';
import { getTimeByParts, isAllDayDate, stringDateToUTC } from '@/utils/time';
import { TASK_DAY_FORMAT, TASK_TIME_FORMAT } from '@/consts/timeFormats';
import { useTaskDescriptionParse } from '@/hooks/useTaskDescriptionParse';
import { useAudioRecord } from '@/hooks/useAudioRecord';
import { useWorkspaceStore } from '@/stores/workspaces';
import { useChanged } from '@/hooks/useChanged';
import { createEventHook } from '@vueuse/core';
import { formatTaskTimeToUTC } from '@/utils/task';

export interface IModalTaskCreateData {
  executor: null | SubordinateViewType;
  audioFile: Blob | null;
  deadline: string;
  deadlineLast?: string;
  notifyTime?: string;
  name: string;
  taskContent: string;
  taskPriority: { title: TTaskPriorityItem; value: TTaskPriorityItem };
  files: File[];
  languageFrom: { title: string; value: string };
  languageTo: { title: string; value: string };
  companyId?: number;
  hardDeadline?: boolean;
}

export const useModalCreateTaskStore = defineStore('modalTaskCreate', () => {
  const userStore = useUserStore();
  const { userData } = storeToRefs(userStore);
  const taskStore = useTasksStore();
  const workspacesStore = useWorkspaceStore();
  const { currentWorkspace } = storeToRefs(workspacesStore);
  const { formatWithLocale } = useCurrentLanguage();

  const { t } = useI18n();

  const subordinates = ref<SubordinateViewType[] | null>(null);
  const isModalTaskCreate = ref(false);
  const isPopupShareLink = ref(false);
  const isSubordinatesFetching = ref(false);

  const activeTaskId = ref<null | number>(null);
  const activeTask = computed(() => {
    return taskStore.tasks?.content.find(
      (task) => task.id === activeTaskId.value,
    );
  });

  const createEvent = createEventHook<{ task: Task; companyId: number }>();

  const initialModalTaskCreateData: () => IModalTaskCreateData = () => ({
    executor: null,
    audioFile: null,
    deadline: '',
    deadlineLast: undefined,
    notifyTime: undefined,
    name: '',
    taskContent: '',
    taskPriority: { title: 'NORMAL', value: 'NORMAL' },
    languageFrom: {
      title: userStore.userData?.locale.name || '',
      value: userStore.userData?.locale.abbreviation || '',
    },
    languageTo: { title: 'French', value: 'french' },
    files: [],
    hardDeadline: false,
  });

  const modalTaskCreateData = ref<IModalTaskCreateData>(
    initialModalTaskCreateData(),
  );

  const audio = reactive(useAudioRecord());

  const {
    parseDescription,
    resetDirtyState,
    dirtyState,
    hasSmartResponse,
    pending: pendingSmartResponse,
  } = useTaskDescriptionParse(modalTaskCreateData, subordinates);

  const {
    isChanged,
    init: initStateChanged,
    reset: resetStateChanged,
  } = useChanged([modalTaskCreateData, () => audio.blobData]);

  const languagesList = computed(() => {
    return [
      { title: 'English', value: 'ENGLISH' },
      { title: 'French', value: 'FRENCH' },
      { title: 'Chinese', value: 'CHINESE' },
    ];
  });

  const getDateView = computed(() => {
    const date = new Date(modalTaskCreateData.value.deadline);
    return isValid(date) ? formatWithLocale(date, 'dd MMMM yyyy') : '';
  });

  async function getSubordinates() {
    try {
      isSubordinatesFetching.value = true;
      const response = await services.positions.getSubordinates({
        pageSize: 1000,
        ...(modalTaskCreateData.value.companyId && {
          companyId: modalTaskCreateData.value.companyId,
        }),
      });
      subordinates.value = response.data.content.map(subordinatesMappingToView);
      if (
        !subordinates.value.find(
          (subordinate) =>
            subordinate.id === modalTaskCreateData.value.executor?.id,
        )
      ) {
        modalTaskCreateData.value.executor = null;
      }
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      isSubordinatesFetching.value = false;
    }
  }

  function setDate(date: Date) {
    modalTaskCreateData.value.deadline = format(
      new Date(date),
      TASK_DAY_FORMAT,
    );

    modalTaskCreateData.value.deadlineLast = undefined;
  }

  function setTime({ startTime, endTime, reminderTime }: SetTaskTimeParams) {
    const currentDate = new Date(modalTaskCreateData.value.deadline);

    if (startTime) {
      const [startHour, startMinutes] = getTimeByParts(startTime);

      modalTaskCreateData.value.deadline = format(
        setMinutes(setHours(currentDate, startHour), startMinutes),
        TASK_TIME_FORMAT,
      );
    } else {
      setDate(currentDate);
    }

    if (endTime) {
      const [endHour, endMinutes] = getTimeByParts(endTime);

      modalTaskCreateData.value.deadlineLast = format(
        setMinutes(setHours(currentDate, endHour), endMinutes),
        TASK_TIME_FORMAT,
      );
    } else {
      modalTaskCreateData.value.deadlineLast = undefined;
    }

    if (reminderTime) {
      const [reminderHour, reminderMinutes] = getTimeByParts(reminderTime);
      modalTaskCreateData.value.notifyTime = format(
        setMinutes(setHours(currentDate, reminderHour), reminderMinutes),
        TASK_TIME_FORMAT,
      );
    } else {
      modalTaskCreateData.value.notifyTime = undefined;
    }
  }

  const createMediaFile = async () => {
    const filesIds: number[] = [];
    if (modalTaskCreateData.value.files.length) {
      const groupedFiles = modalTaskCreateData.value.files.reduce(
        (groupedFiles: Record<string, File[]>, file) => {
          const type =
            filesTypesMapping[
              file.type.split('/')[0] as keyof typeof filesTypesMapping
            ] || 'ATTACHMENT';
          if (!groupedFiles[type]) groupedFiles[type] = [];
          groupedFiles[type].push(file);
          return groupedFiles;
        },
        {},
      );

      for await (const group of Object.keys(groupedFiles)) {
        const formData = new FormData();
        formData.append('fileType', group);

        groupedFiles[group].forEach((file) => {
          formData.append('files', file);
        });

        try {
          const resFiles = await services.files.add(formData);
          resFiles.data.forEach((file) => {
            filesIds.push(file.id);
          });
        } catch (e) {
          ElNotification.error({
            message: t('some_error'),
            offset: 80,
          });
          console.error(e);
        }
      }
    }

    return filesIds;
  };

  const createAudioFiles = async () => {
    let audioFileId = 0;
    if (modalTaskCreateData.value.audioFile) {
      const file = new File(
        [modalTaskCreateData.value.audioFile],
        'voice.mp3',
        { type: 'audio/mp3' },
      );
      const formData = new FormData();
      formData.append('fileType', filesTypes['AUDIO']);
      formData.append('files', file);

      try {
        const audioFiles = await services.files.add(formData);
        audioFileId = audioFiles.data[0].id;
      } catch (e) {
        ElNotification.error({
          message: t('some_error'),
          offset: 80,
        });
        console.error(e);
      }
    }

    return audioFileId;
  };

  function resetTask() {
    audio.resetRecord();
    modalTaskCreateData.value = initialModalTaskCreateData();
    modalTaskCreateData.value.companyId = currentWorkspace.value?.id;
    queueMicrotask(resetDirtyState);
  }

  async function createTask() {
    try {
      if (!modalTaskCreateData.value.taskPriority.value) {
        return ElNotification.error({
          message: 'Select priority',
          offset: 80,
        });
      }
      const [filesIds, audioFileId] = await Promise.all([
        createMediaFile(),
        createAudioFiles(),
      ]);

      const isAllDayTask = isAllDayDate(modalTaskCreateData.value.deadline);

      const deadline = isAllDayTask
        ? modalTaskCreateData.value.deadline
        : stringDateToUTC(modalTaskCreateData.value.deadline);

      if (!deadline) throw new Error();

      const deadlineLast = stringDateToUTC(
        modalTaskCreateData.value.deadlineLast,
      );

      const notifyTime = stringDateToUTC(modalTaskCreateData.value.notifyTime);

      const companyId =
        modalTaskCreateData.value.companyId ||
        userData.value?.currentCompany ||
        0;

      const { data } = await services.tasks.createTask({
        executorId: modalTaskCreateData.value.executor?.id ?? null,
        audioFileId,
        companyId,
        deadline,
        deadlineLast,
        notifyTime,
        filesIds,
        name: modalTaskCreateData.value.name,
        taskContent: modalTaskCreateData.value.taskContent,
        taskPriority: modalTaskCreateData.value.taskPriority.value,
        hardDeadline: modalTaskCreateData.value.hardDeadline,
      });
      ElNotification.success({
        message: t('tasks.create_message'),
        offset: 80,
      });
      data.companyId = companyId;
      createEvent.trigger({
        task: formatTaskTimeToUTC(data),
        companyId,
      });
      isModalTaskCreate.value = false;
      await taskStore.fetch();
      resetTask();
    } catch (e) {
      ElNotification.error({
        message: t('some_error'),
        offset: 80,
      });
      console.error(e);
    }
  }

  watch(
    () => userStore.userData,
    (val) => {
      if (val) {
        modalTaskCreateData.value.languageFrom = {
          title: val?.locale.name,
          value: val?.locale.abbreviation,
        };
      }
    },
  );

  return {
    activeTaskId,
    activeTask,
    modalTaskCreateData,
    isPopupShareLink,
    setDate,
    setTime,
    getDateView,
    languagesList,
    isModalTaskCreate,
    createTask,
    resetTask,
    getSubordinates,
    subordinates,
    isSubordinatesFetching,
    parseDescription,
    dirtyState,
    audio,
    hasSmartResponse,
    pendingSmartResponse,
    isChanged,
    initStateChanged,
    resetStateChanged,
    onCreateTask: createEvent.on,
  };
});
