import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { TokenType } from '@/types/integrations';
import { ThirdPartyRequestParams } from '@/types/user';

export class GoogleIntegrationService extends ApiService {
  getCalendarsList(tokenType: TokenType) {
    return axiosInstance.get(ENDPOINTS.auth.calendars, {
      params: { tokenType },
    });
  }

  addCalendar(auth: ThirdPartyRequestParams, tokenType: TokenType) {
    return axiosInstance.post(ENDPOINTS.auth.calendars, auth, {
      params: { tokenType, web: true },
    });
  }
}
